import * as React from 'react';
const isBrowser = typeof window !== 'undefined';

const SSRPage = () => {
  let loggedIn = false;
  if (isBrowser) {
    window.localStorage.getItem('isLoggedIn') === 'true';
  }

  return (
    <main>
      <h1>Attempting to see the window object</h1>
      <p>Status of the window is {loggedIn}</p>
      {/* <img alt="Happy dog" src={serverData.message} /> */}
    </main>
  );
};
export default SSRPage;

// export async function getServerData() {
//   try {
//     const res = await fetch(`https://dog.ceo/api/breeds/image/random`);
//     if (!res.ok) {
//       throw new Error(`Response failed`);
//     }
//     return {
//       props: await res.json(),
//     };
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {},
//     };
//   }
// }
